import React, { useState } from 'react';
import '../App.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        'full-name': '',
        'mobile-number': '',
        email: '',
        'project-address': '',
        city: '',
        state: '',
        description: ''
    });

    const [error, setError] = useState(null);   // State to manage error messages
    const [success, setSuccess] = useState(false); // State to manage success message
    const [loading, setLoading] = useState(false); // State to manage loading status

    // Handle form input change
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    // Basic client-side validation
    const validateForm = () => {
        if (!/^\d+$/.test(formData['mobile-number'])) {
            setError('Mobile number should contain only digits');
            return false;
        }
        if (!/\S+@\S+\.\S+/.test(formData.email)) {
            setError('Please enter a valid email address');
            return false;
        }
        return true;
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);   // Reset any existing error
        setSuccess(false); // Reset success state

        if (!validateForm()) return;   // Stop if validation fails

        setLoading(true);  // Set loading state

        try {
            const response = await fetch('https://apzheightaccesssolutions.com.au/submit-form.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setSuccess(true);   // Show success message
                setFormData({
                    'full-name': '',
                    'mobile-number': '',
                    email: '',
                    'project-address': '',
                    city: '',
                    state: '',
                    description: ''
                });
            } else {
                const errorData = await response.json();
                setError(`Failed to submit: ${errorData.message || 'Unknown error'}`);
            }
        } catch (error) {
            setError('Error submitting form: ' + error.message);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <div>
            {/* Header Section */}
            <header className="contact-header">
                <h1>Contact Us</h1>
            </header>

            {/* Contact Section */}
            <section className="contact-section">
                <div className="contact-container">
                    <div className="contact-form-wrapper">
                        <h2><span>Get</span> In Touch</h2>
                        <p>Ready to start a project? Contact us today!</p>

                        {/* Display error message */}
                        {error && <div className="error-message" aria-live="polite">{error}</div>}

                        {/* Display success message */}
                        {success && <div className="success-message" aria-live="polite">Form submitted successfully!</div>}

                        {/* Contact Form */}
                        <form className="contact-form" onSubmit={handleSubmit}>
                            {/* Form fields */}
                            <div className="form-group">
                                <label htmlFor="full-name">Full Name</label>
                                <input
                                    type="text"
                                    id="full-name"
                                    name="full-name"
                                    value={formData['full-name']}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="mobile-number">Mobile Number</label>
                                <input
                                    type="tel"
                                    id="mobile-number"
                                    name="mobile-number"
                                    value={formData['mobile-number']}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="project-address">Project Address</label>
                                <input
                                    type="text"
                                    id="project-address"
                                    name="project-address"
                                    value={formData['project-address']}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="city">City</label>
                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="state">State/Region</label>
                                <input
                                    type="text"
                                    id="state"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="form-group full-width">
                                <label htmlFor="description">Description of Service</label>
                                <textarea
                                    id="description"
                                    name="description"
                                    rows="4"
                                    value={formData.description}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <button type="submit" className="submit-button" disabled={loading}>
                                {loading ? 'Submitting...' : 'Submit'}
                            </button>
                        </form>
                    </div>
                    <div className="contact-info-wrapper">
                        <h2>Feel free to reach out with any inquiries, feedback, or simply to connect—we're here and eager to listen.</h2>
                        <ul>
                            <li>We serve Strata, Commercial, Industrial, Government & all Infrastructure.</li>
                            <li>We do service private residential works.</li>
                            <li>We do service buildings above three stories.</li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Contact;
