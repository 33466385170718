// src/components/Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './App.css';
import apzImage from './assets/apz.png';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev); // Toggle menu state
    };

    const closeMenu = () => {
        setIsMenuOpen(false); // Close the menu
    };

    return (
        <header className="header">
            <nav className="navbar">
                <div className="navbar-container">
                    <Link to="/" className="navbar-logo">
                        <img src={apzImage} alt="Logo" className="navbar-logo-img" />
                    </Link>
                    <button className="navbar-toggle" onClick={toggleMenu} aria-label="Toggle Menu">
                        <span className="navbar-toggle-icon">&#9776;</span>
                    </button>
                    <ul className={`navbar-menu ${isMenuOpen ? 'active' : ''}`}>
                        <button className="navbar-close" onClick={closeMenu} aria-label="Close Menu">&times;</button>
                        <li className="navbar-item">
                            <Link to="/about" className="navbar-link" onClick={closeMenu}>About</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to="/paintingservices" className="navbar-link" onClick={closeMenu}>Painting Services</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to="/remedialservices" className="navbar-link" onClick={closeMenu}>Remedial Service</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to="/cleaningservices" className="navbar-link" onClick={closeMenu}>Cleaning Services</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to="/contact" className="navbar-link" onClick={closeMenu}>Contact</Link>
                        </li>
                        <li className="navbar-item">
                            <Link to="/contact" className="navbar-button" onClick={closeMenu}>Get Free Quote</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;
